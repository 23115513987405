<template>
  <v-card elevation="0" class="wrapper-card widget-outer-wrapper">
    <v-card-text v-if="!pluginConfigId" class="widget-outer-container">
      <slot> </slot>
    </v-card-text>
    <iframe v-else-if="src" :src="src" frameborder="0"></iframe>
  </v-card>
</template>

<script>
import api from "@/api";
import getFullApiUrl from "@/utils/formaters/getFullApiUrl";
import { mapState } from "vuex";
import { RAIDA_API } from "../../../../env/.env.js";

export default {
  props: {
    pluginConfigId: {
      type: String,
      default: "",
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      src: "",
    };
  },
  computed: {
    ...mapState("core$common", ["user"]),
  },
  methods: {
    getPluginConfig() {
      api
        .request({
          name: "getPluginConfig",
          pathParam: {
            nodeId: this.nodeId,
            pluginConfigId: this.pluginConfigId,
          },
        })
        .then(({ data }) => this.initPlugin(data.data));
    },
    initPlugin(data) {
      const { src, config } = data;
      const additionalData = {
        a: RAIDA_API,
        t: localStorage.getItem("_t"),
        h: location.href,
        n: this.nodeId,
      };
      const additionalDataEncoded = btoa(JSON.stringify(additionalData));
      const pluginPath = `${src}?config=${config}&d=${additionalDataEncoded}`;

      this.src = getFullApiUrl(pluginPath);
    },
  },
  created() {
    if (!this.pluginConfigId) return;

    this.getPluginConfig();
  },
};
</script>

<style scoped lang="scss">
.wrapper-card {
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border-width: 0px;
  }
}
</style>
