<template>
  <grid-layout
    :layout.sync="layout"
    :colNum="colNum"
    :rowHeight="rowHeight"
    :isDraggable="isDraggable"
    :isResizable="isDraggable"
    use-css-transforms
    :margin="margin"
  >
    <grid-item
      v-for="item in layout"
      :key="item.i"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      :maxW="item.maxW || Infinity"
      :maxH="item.maxH || Infinity"
      :minW="item.minW || 1"
      :minH="item.minH || 1"
    >
      <v-hover>
        <template #default="{ hover }">
          <v-card
            height="100%"
            :elevation="isDraggable ? '1' : '0'"
            class="grid-card"
          >
            <v-card-text class="fill-height pa-0">
              <slot ref="widget" :index="item.i"></slot>
            </v-card-text>
            <v-fade-transition>
              <v-overlay
                v-if="isDraggable && hover"
                absolute
                color="#34395721"
              />
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </grid-item>
  </grid-layout>
</template>

<script>
import VueGridLayout from "vue-grid-layout";

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    layout: {
      type: Array,
      default: () => [],
    },
    margin: {
      type: Array,
      default: () => [15, 15],
    },
    rowHeight: {
      type: Number,
      default: 30,
    },
    colNum: {
      type: Number,
      default: 48,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.vue-grid-item.vue-grid-placeholder {
  background: var(--v-primary-lighten1) !important;
}
.vue-grid-item.vue-grid-placeholder {
  background: var(--v-primary-lighten1) !important;
}
.vue-grid-item > .vue-resizable-handle {
  z-index: 99;
}
.grid-card {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

@media (max-width: 600px) {
  .vue-grid-item {
    transform: translate3d(0, 0, 0) !important;
    width: auto !important;
    // height: auto !important;
    position: initial !important;
    margin-bottom: 30px !important;
  }
}
</style>
