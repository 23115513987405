import TreeService from "../treeService";

const SYSTEM_OBJECT_FIELDS = ["assignee", "status", "author", "process"];
const GRID_COUNT = 12;
const CUSTOM_FIELD_PREFIX = "cf_";
const CUSTOM_FIELDS_PREFIX = "custom_fields";

/**
 * @param {array} fields поля формы
 * @return {object} возвращает плоскую структуру формы без значений
 */
const getFormDataStructure = (fields) => {
  const result = {};

  fields.forEach((field) => {
    const value = field.structure
      ? TreeService.treeCollectStructure(field.structure)
      : "";
    result[field.name] = value;
  });

  return result;
};

const getTitle = ({ text, field, toLocaleMethod }) => {
  const preDefinedtext = text[field.name];
  if (preDefinedtext) return preDefinedtext;

  return toLocaleMethod(field.title, true);
};

const getPlaceholder = ({ field, toLocaleMethod }) => {
  if (!field.placeholder) return toLocaleMethod(field.title, true);

  return toLocaleMethod(field.placeholder, true);
};

const getFieldOptions = ({ users, statuses, field }) => {
  switch (field.name) {
    case "status":
      return statuses;
    case "author":
    case "assignee":
      return users;
    default:
      return field.options;
  }
};

const getFieldOptionText = ({ field }) => {
  switch (field.name) {
    case "status":
      return "name";
    case "author":
    case "assignee":
      return "_fullname";
    default:
      return field.options ? "title" : null;
  }
};

const getFieldOptionValue = ({ field }) => {
  if (SYSTEM_OBJECT_FIELDS.includes(field.name)) return "id";

  return field.options ? "value" : null;
};

const getFieldType = ({ field, form }) => {
  if (field.type !== "file") return field.type;

  return form.type === "edit" ? "file_viewer" : "file_loader";
};

const getParentFieldName = (fieldName) => {
  if (!fieldName) return "";

  const splited = fieldName.split(".");

  return splited[0];
};

const isCustomField = (fieldName) => {
  return fieldName.startsWith(CUSTOM_FIELD_PREFIX);
};

const isFieldReadonly = ({ fieldName, editableFields, isFormReadonly }) => {
  if (!editableFields || !editableFields.length) return !!isFormReadonly;

  const isFieldReadonly = !editableFields.includes(fieldName);

  if (isFieldReadonly) return true;

  return !!isFormReadonly;
};

const isFieldVisible = ({ fieldName, visibleFields, displayFields }) => {
  if (displayFields && fieldName in displayFields) {
    if (displayFields[fieldName]?.display === "hidden") return false;
  }
  if (!visibleFields || !visibleFields.length) return true;

  return visibleFields.includes(fieldName);
};

export {
  CUSTOM_FIELDS_PREFIX,
  GRID_COUNT,
  SYSTEM_OBJECT_FIELDS,
  getFieldOptionText,
  getFieldOptionValue,
  getFieldOptions,
  getFieldType,
  getFormDataStructure,
  getParentFieldName,
  getPlaceholder,
  getTitle,
  isCustomField,
  isFieldReadonly,
  isFieldVisible,
};
